import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typed from 'typed.js';

export default function PageSite() {

    const [isNavActive, setIsNavActive] = useState(false);
    const [activeSection, setActiveSection] = useState('#hero');

    useEffect(() => {
        AOS.init({ duration: 1000 });

        const options = {
            strings: ['Desenvolvimento Web', 'Soluções Personalizadas', 'Aplicativos Móveis', 'Automação de Processos', 'Consultoria em TI', 'Integrações de Sistemas', 'E-commerce', 'Plataformas Digitais'],
            typeSpeed: 50,
            backSpeed: 50,
            loop: true
        };
        const typed = new Typed('.typed', options);
        return () => {
            typed.destroy();
        };
    }, []);


    const toggleBodyClass = () => {
        const body = document.body;
        if (body.classList.contains('mobile-nav-active')) {
            body.classList.remove('mobile-nav-active');
        } else {
            body.classList.add('mobile-nav-active');
        }
        setIsNavActive(!isNavActive);
    };
    const removeToggleBodyClass = (section: string) => {
        const body = document.body;
        body.classList.remove('mobile-nav-active');
        setIsNavActive(!isNavActive);
        setActiveSection(section);
    };

    return (
        <>
            <i onClick={toggleBodyClass} className={`bi mobile-nav-toggle d-lg-none ${isNavActive ? 'bi-x' : 'bi-list'}`}></i>

            <header id="header" className="d-flex flex-column justify-content-center">
                <nav id="navbar" className="navbar nav-menu">
                    <ul>
                    <li onClick={() => removeToggleBodyClass('#hero')}>
                            <a href="#hero" className={`nav-link scrollto ${activeSection === '#hero' ? 'active' : ''}`}>
                                <i className="bx bx-home"></i> <span>Início</span>
                            </a>
                        </li>
                        <li onClick={() => removeToggleBodyClass('#about')}>
                            <a href="#about" className={`nav-link scrollto ${activeSection === '#about' ? 'active' : ''}`}>
                                <i className="bx bx-user"></i> <span>Sobre</span>
                            </a>
                        </li>
                        <li onClick={() => removeToggleBodyClass('#services')}>
                            <a href="#services" className={`nav-link scrollto ${activeSection === '#services' ? 'active' : ''}`}>
                                <i className="bx bx-server"></i> <span>Serviços</span>
                            </a>
                        </li>
                        <li onClick={() => removeToggleBodyClass('#contact')}>
                            <a href="#contact" className={`nav-link scrollto ${activeSection === '#contact' ? 'active' : ''}`}>
                                <i className="bx bx-envelope"></i> <span>Contato</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>

            <section id="hero" className="d-flex flex-column justify-content-center">
                <div className="container" data-aos="zoom-in" data-aos-delay="100">
                    <h1>Oliver Soft-Tech Solução de Software para você!</h1>
                    <p>Oferecemos <span className="typed"></span></p>
                    <div className="social-links">
                        <a href="#" className="twitter"><i className="bx bxl-html5"></i></a>
                        <a href="#" className="facebook"><i className="bx bxl-javascript"></i></a>
                        <a href="#" className="instagram"><i className="bx bxl-php"></i></a>
                        <a href="#" className="linkedin"><i className="bx bxl-c-plus-plus"></i></a>
                        <a href="#" className="linkedin"><i className="bx bxl-bootstrap"></i></a>
                        <a href="#" className="linkedin"><i className="bx bxl-css3"></i></a>
                    </div>
                </div>
            </section>

            <main id="main">
                <section id="about" className="about">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Sobre</h2>
                            <p>Na Oliver Soft-Tech, oferecemos soluções de software inovadoras e personalizadas para atender às necessidades do seu negócio. Com anos de experiência e uma equipe dedicada, estamos prontos para transformar suas ideias em realidade digital.</p>
                        </div>

                        <div className="content">
                            <h3>Desenvolvimento Full Stack &amp; Soluções Tecnológicas</h3>
                            <p className="fst-italic">
                                Na Oliver Soft-Tech, nos especializamos em desenvolvimento web, aplicativos móveis, automação de processos e muito mais. Nossa missão é proporcionar tecnologia de ponta que impulsione o sucesso do seu negócio.
                            </p>
                            <p>
                                Acreditamos que cada projeto é único e merece uma abordagem personalizada. Nosso compromisso é entregar soluções que não apenas atendam, mas superem as expectativas dos nossos clientes. Entre em contato e descubra como podemos ajudar a transformar o seu negócio com tecnologia de ponta.
                            </p>
                        </div>

                    </div>
                </section>

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Serviços</h2>
                            <p>Transforme Suas Ideias em Realidade com um Desenvolvedor Full Stack Especializado! Você tem uma ideia brilhante para um aplicativo ou precisa de um site robusto e dinâmico para seu negócio? Pare de procurar! Eu sou um desenvolvedor full stack com ampla experiência em transformar conceitos em soluções digitais poderosas.</p>
                        </div>

                        <div className="row">

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box iconbox-blue">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Desenvolvimento Completo</a></h4>
                                    <p>Da concepção à implementação, cuido de tudo para garantir que seu projeto seja um sucesso.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box iconbox-orange ">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Front-End Dinâmico</a></h4>
                                    <p>Interfaces de usuário atraentes e responsivas que oferecem uma experiência excepcional.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box iconbox-pink">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Back-End Eficiente</a></h4>
                                    <p>Estruturas de servidor robustas e escaláveis que garantem desempenho impecável.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box iconbox-yellow">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Integração de API</a></h4>
                                    <p>Conexões perfeitas com serviços de terceiros para ampliar as funcionalidades do seu projeto.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box iconbox-red">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Segurança e Performance</a></h4>
                                    <p>Implementação de práticas de segurança avançadas e otimização de performance para garantir um produto final confiável e rápido.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box iconbox-teal">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Experiência Comprovada</a></h4>
                                    <p>Anos de experiência em projetos variados, desde startups a empresas estabelecidas.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                                <div className="icon-box iconbox-blue">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Abordagem Personalizada</a></h4>
                                    <p>Cada projeto é único. Eu trabalho de perto com você para entender suas necessidades e fornecer soluções sob medida.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                                <div className="icon-box iconbox-orange ">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Tecnologias Modernas</a></h4>
                                    <p>Utilizo as tecnologias mais recentes e comprovadas para garantir que seu produto esteja na vanguarda do mercado.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                                <div className="icon-box iconbox-pink">
                                    <div className="icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>
                                    <h4><a href="">Entrega Pontual</a></h4>
                                    <p>Compromisso com prazos e entrega dentro do cronograma acordado.</p>
                                </div>
                            </div>

                        </div>
                        <br /><br />
                        <div className="section-title">
                            <h3>Depoimentos de Clientes</h3>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <blockquote className="testimonial">
                                    <p>"Foi uma experiência incrível. Ele transformou nossas ideias em uma plataforma funcional que superou nossas expectativas." - Cliente Satisfeito</p>
                                </blockquote>
                            </div>
                            <div className="col-lg-6">
                                <blockquote className="testimonial">
                                    <p>"Extremamente profissional e dedicado. Seu trabalho no desenvolvimento do nosso site foi excepcional." - Cliente Feliz</p>
                                </blockquote>
                            </div>
                        </div>
                        <br /><br />
                        <div className="section-title">
                            <h3>Vamos Conversar!</h3>
                            <p>Pronto para dar vida à sua ideia? Entre em contato hoje mesmo para uma consulta gratuita e veja como posso ajudar a transformar seu projeto em realidade.</p>
                        </div>

                    </div>
                </section>

                <section id="contact" className="contact">
                    <div className="container" data-aos="fade-up">

                        <div className="section-title">
                            <h2>Contato</h2>
                        </div>

                        <div className="row mt-1">

                            <div className="col-lg-4">
                                <div className="info">
                                    <div className="address">
                                        <i className="bi bi-geo-alt"></i>
                                        <h4>Localização:</h4>
                                        <p>Cristalina-GO</p>
                                    </div>

                                    <div className="email">
                                        <i className="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>oliversofttech1@gmail.com</p>
                                    </div>

                                    <div className="phone">
                                        <i className="bi bi-phone"></i>
                                        <h4>Telefone:</h4>
                                        <p>+55 (61) 98567-7524</p>
                                    </div>

                                </div>

                            </div>

                            <div className="col-lg-8 mt-5 mt-lg-0">
                                <a href="https://wa.me/5561985677524" target="_blank" className="btn btn-success">
                                    <i className="bi bi-whatsapp"></i> Fale Conosco pelo WhatsApp
                                </a>
                            </div>

                        </div>

                    </div>
                </section>

            </main>

            <footer id="footer">
                <div className="container">
                    <h3>Oliver Soft-Tech</h3>
                    <p>Proporcionamos soluções inovadoras para seu negócio com qualidade e eficiência. Entre em contato e descubra como podemos ajudar você a alcançar seus objetivos.</p>
                    <div className="social-links">
                        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                        <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                        <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                    </div>
                    <div className="copyright">
                        &copy; Copyright <strong><span>Oliver Soft-Tech</span></strong>. Todos os direitos reservados
                    </div>
                    <div className="credits">
                        Design por <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </footer>
        </>
    );
}
